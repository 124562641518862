import styled from "styled-components";
import { Images } from "../../theme";

export const TournamentMatchResultsWrapper = styled.div`
  background-image: url(${Images.BG_TOURNAMENT_MATCH_RESULTS});
  width: 100%;
  position: relative;
  min-height: 100%;
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    background-image: url(${Images.BG_TOURNAMENT_MATCH_RESULTS_MOBILE});
  }

  .ant-spin-spinning {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-spin-spinning:before {
    content: "";
    background: #ececec;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.2;
  }

  .anticon {
    color: #fff;
  }
`;
