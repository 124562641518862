import React from "react";

const GameIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.50004 15.5834H14.1667M11.3334 12.75V18.4167M21.25 17H21.2642M25.5 14.1667H25.5142M24.5367 7.08337H9.46337C8.06127 7.0837 6.70907 7.60382 5.66817 8.5432C4.62727 9.48257 3.97159 10.7745 3.82787 12.1692C3.81937 12.2429 3.81371 12.3123 3.80379 12.3845C3.68904 13.3394 2.83337 20.4794 2.83337 22.6667C2.83337 23.7939 3.28114 24.8749 4.07817 25.6719C4.8752 26.4689 5.9562 26.9167 7.08337 26.9167C8.50004 26.9167 9.20837 26.2084 9.91671 25.5L11.9199 23.4969C12.4511 22.9655 13.1717 22.6669 13.923 22.6667H20.077C20.8284 22.6669 21.549 22.9655 22.0802 23.4969L24.0834 25.5C24.7917 26.2084 25.5 26.9167 26.9167 26.9167C28.0439 26.9167 29.1249 26.4689 29.9219 25.6719C30.7189 24.8749 31.1667 23.7939 31.1667 22.6667C31.1667 20.478 30.311 13.3394 30.1963 12.3845C30.1864 12.3137 30.1807 12.2429 30.1722 12.1706C30.0288 10.7756 29.3733 9.48339 28.3323 8.54372C27.2914 7.60406 25.939 7.08374 24.5367 7.08337Z"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default GameIcon;
