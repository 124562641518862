import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useLocation, useNavigate } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../stores';
import { getListEventsAction } from '../../stores/screens/events/events.action';
import { getPointsTopAction } from '../../stores/screens/points/points.action';
import {
  redirectMccBetAction,
  refreshTokenAction,
  retrieveAccountAction,
} from '../../stores/screens/auth/auth.action';
import * as api from '../../api';
import useJoinGame from '../../hooks/joinGame';
import Storage from '../../utils/storage';
import { useWeb3Auth } from '../../contexts/web3auth';
import useSSOLogin from '../../hooks/ssoLogin';

/** component */
import ChatBot from '../../components/chatbot';
import Page from '../../components/page';
import SectionCommunities from '../../components/sections/communities';
import SectionFaqs from '../../components/sections/faqs';
import SectionRanking from '../../components/sections/ranking';
import SectionServices from '../../components/sections/services';
import SectionTop from '../../components/sections/top';
import ModalGenerateVR from '../../components/modal-generate-vr';
import ModalGenerateDevice from '../../components/modal-device';

/** asset */
import { Content } from './styled';
// import { chainOptions } from '../../utils/auth';
import { ModalVerifyCodeAuthStyled } from '../Register/form-register/styled';
import { ModalLinkTelegram } from '../../components/modal-confirm-link-telegram';
import { parseQueryStringToParams } from '../../utils/common';

function Home() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { auth_token } = useSSOLogin();
  const { handleAsGuest, handleJoinGame } = useJoinGame();
  const { accessToken, handleLogout } = useWeb3Auth();
  const [isOpenPopupVR, setIsOpenPopupVR] = useState(false);
  const [isOpenPopupDevice, setIsOpenPopupDevice] = useState(false);
  const isLogin = !!Storage.get('ACCESS_TOKEN');
  const dispatch = useAppDispatch();
  const [showConfirmModalTelegram, setShowConfirmModalTelegram] =
    useState(false);

  const { userInfo } = useAppSelector((state) => state.auth);
  useEffect(() => {
    if (userInfo && !!Storage.get('TELEGRAM_PROFILE')) {
      const { init_data } = JSON.parse(Storage.get('TELEGRAM_PROFILE'));
      const user = parseQueryStringToParams(init_data)
      if (
        !userInfo?.linked ||
        (userInfo?.linked && userInfo?.telegram_uuid !== user?.id)
      ) {
        setShowConfirmModalTelegram(true);
      }
    }
  }, [userInfo]);

  // useEffect(() => {
  //   const checkNetworkChange = async () => {
  //     const allwedNetworks = chainOptions.map((option) =>
  //       option?.value?.toString()
  //     );

  //     window.ethereum.on('networkChanged', async function (networkId) {
  //       if (!allwedNetworks.includes(networkId)) {
  //         try {
  //           await handleLogout();
  //         } catch (error) {
  //           notification.error({
  //             message: error?.message || 'Something went wrong',
  //             duration: 5,
  //           });
  //         }
  //       }
  //     });
  //   };
  //   checkNetworkChange();
  // }, []);

  const handleSubmitCrossSite = async ({ accounts, identifier }) => {
    try {
      const { code, data } = await api.loginSSo({
        auth_token: accounts,
        identifier: identifier,
      });
      if (code === 200) {
        window.open(
          `${process.env.REACT_APP_SITE_MP_URL}/ja?authToken=${data.accessToken}&userId=${data.userId}&publicAddress=${identifier}`,
          '_blank'
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        try {
          const { payload } = await dispatch(
            refreshTokenAction({
              auth_token: accounts,
              identifier: identifier,
            })
          );
          if (payload?.data && payload.data?.access_token) {
            return handleSubmitCrossSite({
              accounts: payload?.data?.access_token,
              identifier,
            });
          }
        } catch (error) {
          if (error?.response?.status === 401) {
            handleLogout();
            navigate('/login');
          } else {
            notification.error({
              message: error?.message || 'Something went wrong',
              duration: 5,
            });
          }
        }
      } else {
        notification.error({
          message: error?.message || 'Something went wrong',
          duration: 5,
        });
      }
    }
  };

  const handleSSO = async () => {
    if (isLogin) {
      const { payload } = await dispatch(
        retrieveAccountAction(accessToken || auth_token)
      );

      if (payload?.status) {
        handleSubmitCrossSite({
          accounts: payload?.data.accounts[0].auth_token,
          identifier: payload?.data.accounts[0].identifier,
        });
      }
    } else {
      window.open(process.env.REACT_APP_SITE_MP_URL, '_blank');
    }
  };

  useEffect(() => {
    fetchDataEvents();
    fetchDataPointsTop();
  }, []);

  const fetchDataEvents = () => {
    dispatch(
      getListEventsAction({
        limit: 3,
        page: 1,
      })
    );
  };

  const fetchDataPointsTop = () => {
    dispatch(
      getPointsTopAction({
        limit: 5,
      })
    );
  };

  const onOpenGenerateVR = () => {
    setIsOpenPopupVR(true);
  };

  const handleMccBET = async () => {
    if (isLogin) {
      const { payload } = await dispatch(
        redirectMccBetAction(accessToken || auth_token)
      );

      if (payload?.status) {
        window.open(
          process.env.REACT_APP_SITE_MCC_BET_URL + `?ext_token=${payload.data}`,
          '_blank'
        );
      } else {
        notification.error({
          message: payload?.message
            ? payload?.message.replace('This site', 'MCC BET')
            : 'Something went wrong',
          duration: 5,
        });
      }
    } else {
      window.open(process.env.REACT_APP_SITE_MCC_BET_URL, '_blank');
    }
  };

  return (
    <Page className="home">
      <Content className="content">
        <SectionTop
          isLogin={isLogin}
          randomKey={state?.randomKey}
          handleJoinGame={handleJoinGame}
          handleAsGuest={() => handleAsGuest(state?.randomKey)}
          onOpenGenerateVR={onOpenGenerateVR}
          onOpenDevice={() => setIsOpenPopupDevice(true)}
        />
        <SectionServices handleSSO={handleSSO} handleMccBET={handleMccBET} />
        <SectionRanking />
        <SectionCommunities />
        <SectionFaqs />
        {/* <SectionCollaborations /> */}
      </Content>

      {/* <ChatBot /> */}
      <ModalGenerateVR
        accessToken={accessToken}
        isOpen={isOpenPopupVR}
        onCancel={() => setIsOpenPopupVR(false)}
      />
      <ModalGenerateDevice
        accessToken={accessToken}
        isOpen={isOpenPopupDevice}
        onCancel={() => setIsOpenPopupDevice(false)}
      />

      <ModalVerifyCodeAuthStyled
        open={showConfirmModalTelegram}
        width={535}
        footer={null}
        top
        closable={false}
      >
        <ModalLinkTelegram
          closeModal={() => {
            setShowConfirmModalTelegram(false);
            Storage.remove('TELEGRAM_PROFILE');
            navigate('/');
          }}
        ></ModalLinkTelegram>
      </ModalVerifyCodeAuthStyled>
    </Page>
  );
}

export default Home;
