import styled from "styled-components";

export const ReferralsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .referrals {
    .content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border-radius: 10px;
        
        &:first-child {
          background: linear-gradient(to left, #5FD87F, #178781);
        }

        &:last-child {
          border: 1px solid #5FD87F;
        }

        @media (max-width: 1080px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
        }

        .content-link {
          display: flex;
          flex-direction: column;
          width: 60%;

          .item-title {
            color: #000000;
            font-weight: 700;
          }

          .url {
            text-decoration: underline;
            font-weight: 700;
          }

          @media (max-width: 1080px) {
            width: 100%;
          }
        }

        .content-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 60%;

          @media (max-width: 1080px) {
            width: 100%;
          }

          p {
            text-align: center;
          }
        }

        .item-title {
          text-transform: uppercase;
          font-weight: 700;
        }

        .value {
          font-weight: 700;
          color: #42FCA4;
          font-size: 18px;
        }
      }
    }
  }
`;
