import { useEffect } from "react";
import { Image } from "antd";

import BaseTable from "../../../components/base-table";
import { useAppDispatch, useAppSelector } from "../../../stores";
import { TabMCCQuestsWrapper } from "./styled";
import {
  claimQuestAction,
  getFreePoint,
  getListMCCQuestsAction,
  performQuestAction,
} from "../../../stores/screens/quests/quests.action";
import { SOURCE_TYPE, USER_QUESTS_STATUS } from "../../../constants";
import FacebookIcon from "../../../resources/svgs/FacebookIcon";
import TwitterIcon from "../../../resources/svgs/TwitterIcon";
import YoutubeIcon from "../../../resources/svgs/YoutubeIcon";
import actions from "../../../stores/screens/quests/quests.reducer";
import ButtonImage from "./../../../components/button-img";
import { ICON_TELEGRAM, FREE_POINT } from "../../../theme/images";
import { getUserInfoAction } from "../../../stores/screens/auth/auth.action";
import Storage from "../../../utils/storage";

const TabMCCQuests = ({ filter, setFilter }) => {
  const dispatch = useAppDispatch();
  const { listQuests } = useAppSelector((state) => state.quests);

  const { resetQuestsAction } = actions;

  const getIcon = (source_type) => {
    return (
      <div className="icon">
        {source_type === SOURCE_TYPE.FACEBOOK && <FacebookIcon />}
        {source_type === SOURCE_TYPE.X && <TwitterIcon />}
        {source_type === SOURCE_TYPE.YOUTUBE && <YoutubeIcon />}
        {source_type === SOURCE_TYPE.TELEGRAM && (
          <img width={28} height={28} src={ICON_TELEGRAM} alt="free-point" />
        )}
      </div>
    );
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: "5%",
      render: (value, record, index) => index + 1 + 10 * (listQuests.page - 1),
    },
    {
      title: "Explanation",
      dataIndex: "title",
      key: "title",
      align: "left",
      width: "50%",
      render: (value, record) => (
        <div className="ellipsis">
          <div>{getIcon(record?.source_type)}</div>
          <p>{value || ""}</p>
        </div>
      ),
    },
    {
      title: `Reward`,
      dataIndex: "reward",
      align: "right",
      width: "25%",
      render: (reward) => (
        <>
          {reward}{" "}
          <Image
            preview={false}
            width={28}
            height={28}
            src={FREE_POINT}
            alt="free-point"
          />
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "user_quest_status",
      width: "20%",
      render: (_, record) => (
        <div className="user-quests-status">{renderStatus(record)}</div>
      ),
    },
  ];

  const handleOnChange = (page) => {
    setFilter({
      page: page.current,
      limit: page.pageSize,
    });
  };

  const handlePerform = (record) => {
    const { id, content, source_type, type } = record;
    let url = JSON.parse(content)?.link;
    const encodedContent = encodeURIComponent(JSON.parse(content)?.content);

    const shareConfig = {
      [SOURCE_TYPE.FACEBOOK]: {
        baseUrl: "https://www.facebook.com/sharer/sharer.php?u=",
        param: "quote",
      },
      [SOURCE_TYPE.X]: {
        baseUrl: "https://twitter.com/intent/post?url=",
        param: "text",
      },
    };

    const config = shareConfig[source_type];

    if (type === "SHARE") {
      url = `${config.baseUrl}${url}&${config.param}=${encodedContent}`;
    }

    dispatch(
      performQuestAction({
        id,
        callback: () => {
          dispatch(getFreePoint({}));
          window.open(`${url}`);
          dispatch(getListMCCQuestsAction(filter));
        },
      })
    );
  };

  const handleClaim = ({ id }) => {
    dispatch(
      claimQuestAction({
        id,
        callback: () => {
          dispatch(getFreePoint({}));
          dispatch(getListMCCQuestsAction(filter));
          const timeoutId = setTimeout(() => {
            dispatch(
              getUserInfoAction({ accessToken: Storage.get("ACCESS_TOKEN") })
            );
          }, 1000);

          return () => clearTimeout(timeoutId);
        },
      })
    );
  };

  const rowClassName = (record) => {
    if (record.user_quest_status === "CLAIMED") {
      return "row-claimed";
    }
  };

  const renderStatus = (record) => {
    const { user_quest_status, id } = record;

    switch (user_quest_status) {
      case USER_QUESTS_STATUS.TODO:
        return (
          <ButtonImage
            className="btn-perform"
            onClick={() => handlePerform(record)}
            height={40}
            textTransform="unset"
            fontWeight={300}
            fontSize={14}
            type="action"
          >
            Perform
          </ButtonImage>
        );

      case USER_QUESTS_STATUS.COMPLETED:
        return (
          <ButtonImage
            className="btn-claim"
            onClick={() => handleClaim({ id })}
            height={40}
            textTransform="unset"
            fontWeight={300}
            fontSize={14}
            type="green"
          >
            Claim
          </ButtonImage>
        );

      default:
        return (
          <span style={{ color: "#42FCA4", fontWeight: 700 }}>Completed!</span>
        );
    }
  };

  useEffect(() => {
    dispatch(getListMCCQuestsAction(filter));

    return () => {
      dispatch(resetQuestsAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.page]);

  return (
    <TabMCCQuestsWrapper>
      <BaseTable
        className="quests-table"
        data={listQuests.items}
        rowClassName={rowClassName}
        columns={columns}
        pagination={
          listQuests.totalCount > listQuests.limit
            ? {
                current: listQuests.page,
                pageSize: listQuests.limit,
                total: listQuests.totalCount,
              }
            : false
        }
        onChange={handleOnChange}
      />
    </TabMCCQuestsWrapper>
  );
};

export default TabMCCQuests;
