import styled from "styled-components";

export const GroupsStyled = styled.div`
  width: 100%;
  height: 100%;
  background: radial-gradient(
      68.28% 68.28% at 50% 149.04%,
      #156df2 0%,
      rgba(21, 109, 242, 0) 100%
    ),
    #0c0d22;
  min-height: calc(100vh - 92px);

  .content {
    max-width: 1440px;
    margin: 0 auto;

    .ant-tabs {
      margin-top: 20px;

      .ant-tabs-tab {
        .ant-tabs-tab-btn {
          color: #61637a;
          font-weight: 700;
        }

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #156df2;
          }
        }
      }

      .ant-tabs-nav {
        width: 60%;
        margin: 0 auto;
        margin-bottom: 27px;
      }

      .ant-tabs-nav::before {
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);
      }
    }
  }
`;
