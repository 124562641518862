import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import RpsTournamentsApi from "./rps-tournaments.api";

export const getMatchResultsRpsTournamentAction = createAsyncThunk(
  "GET_MATCH_RESULTS_RPS_TOURNAMENT",
  async (params, { _, rejectWithValue }) => {
    try {
      const response = await RpsTournamentsApi.getMatchResultsRpsTournament(
        params
      );

      return response;
    } catch (err) {
      notification.error({
        message: err?.message,
        duration: 5,
      });

      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);
