import React, { useEffect, useState } from "react";
import { GroupsStyled } from "./styled";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useAppDispatch, useAppSelector } from "../../stores";
import { BG_COVER } from "../../theme/images";
import { getUserInfoAction } from "../../stores/screens/auth/auth.action";
import Storage from "../../utils/storage";

import TabsComponent from "../../components/tabs";
import ProfileTab from "./Profile";

const Groups = () => {
  const dispatch = useAppDispatch();
  const { userInfo, isLoading } = useAppSelector((state) => state.auth);
  const [params, setParams] = useState("");

  const [activeTab, setActiveTab] = useState(0);

  const onChangeTab = (value) => {
    setActiveTab(Number(value));
  };

  const getInfo = () => {
    dispatch(getUserInfoAction({ accessToken: Storage.get("ACCESS_TOKEN") }));
  };

  useEffect(() => {
    if (userInfo) {
      const prm = btoa(
        JSON.stringify({
          accountEmail: userInfo?.email,
          referral: userInfo?.referral_code,
        })
      );
      setParams(prm);
    }
  }, [userInfo]);

  useEffect(() => {
    getInfo();
    /* eslint-disable */
  }, []);

  const tabItems = [
    {
      title: "PROFILE",
      content: <ProfileTab userInfo={userInfo} params={params} />,
    },
    {
      title: "BADGE COLLECTION",
      content: "ttt",
    },
    {
      title: "DIVISIONS",
      content: "sdsadas",
    },
    {
      title: "REFERRAL",
      content: "sdas",
    },
  ];

  return (
    <GroupsStyled bgImage={BG_COVER}>
      {isLoading && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
              }}
              spin
            />
          }
        />
      )}

      <div className="content">
        <TabsComponent
          items={tabItems}
          onChangeTab={onChangeTab}
          activeKey={activeTab}
          tabBarGutter={80}
          size='large'
        ></TabsComponent>
      </div>
    </GroupsStyled>
  );
};

export default Groups;
