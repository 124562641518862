import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import Web3 from 'web3';

import Storage from '../utils/storage';
import { useAppDispatch } from '../stores';
import { getAccountCheckIpAction } from '../stores/screens/accounts/accounts.action';

import MaskLoading from '../components/mask-loading';
import { ADVERTISE_PATHNAME } from '../pages/Statistics/constant';

function Init({ children }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const web3 = new Web3(window.ethereum);

  const paramsUrl = new URLSearchParams(search);
  const source = paramsUrl.get('source');
  const telegramData = paramsUrl.get('telegramData');
  useEffect(() => {
    init();
  }, []);

  const checkPathname = (pathname) => {
    return ADVERTISE_PATHNAME.some((path) => pathname.includes(path));
  };

  const init = async () => {
    try {
      const chainId = await web3.eth.getChainId();
      Storage.set('chain', chainId);

    } catch (error) {
       // eslint-disable-next-line no-console
      console.error('Error getting chainId:', error);
    }

    if (source === 'MCCBET') {
      MaskLoading.open({});
      const { payload } = await dispatch(getAccountCheckIpAction());

      if (payload.data === false) {
        navigate('/country-blocked');
        return;
      }
      MaskLoading.close();
    }

    if (Storage.get('ACCESS_TOKEN') && Storage.get('PUBLIC_ADDRESS')) {
      if (!checkPathname(pathname)) {
        navigate('/');
      }
    }

    if (telegramData) {
      try {
        const profile = atob(telegramData);
        JSON.parse(profile);
        Storage.set('TELEGRAM_PROFILE', profile);

        if (!Storage.get('ACCESS_TOKEN') && !Storage.get('FIRST_LOGIN')) {
          Storage.set('FIRST_LOGIN', true);
          notification.warning({
            message: 'You can login to explore the MCC system and link to your telegram account.',
            duration: 7,
            placement: 'top'
          });
        }
      } catch (e) {
        notification.error({
          message: 'The data provided from Telegram is incorrect.',
          duration: 5,
        });
        Storage.remove('TELEGRAM_PROFILE');
      }
    }
  };

  return children;
}

export default Init;
