import { useEffect } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useAppDispatch, useAppSelector } from "../../../stores";

import actions from "../../../stores/screens/quests/quests.reducer";
import { ReferralsWrapper } from "./styled";
import {
  getReferralQuestInfoAction,
  referralClaimQuestAction,
} from "../../../stores/screens/quests/quests.action";
import Toast from "../../../components/toast";
import ButtonImage from "./../../../components/button-img";
import { getUserInfoAction } from "../../../stores/screens/auth/auth.action";
import Storage from '../../../utils/storage';
import { FREE_POINT } from "../../../theme/images";

const Referrals = () => {
  const dispatch = useAppDispatch();
  const { referralQuest } = useAppSelector((state) => state.quests);
  const { userInfo } = useAppSelector((state) => state.auth);
  const { info, isLoading } = referralQuest;
  const { point_unclaimed, referral_unclaimed, total_referral } = info;
  const url = userInfo?.referral_code ? window.location.origin + '/register?ref=' + userInfo?.referral_code : "";

  const { resetReferralsQuestsAction } = actions;

  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(() => {
      Toast.show("Copied to clipboard!");
    });
  };

  const handleClaim = () => {
    dispatch(
      referralClaimQuestAction({
        callback: () => {
          dispatch(getReferralQuestInfoAction());
          const timeoutId = setTimeout(() => {
            dispatch(
              getUserInfoAction({ accessToken: Storage.get("ACCESS_TOKEN") })
            );
          }, 1000);

          return () => clearTimeout(timeoutId);
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getReferralQuestInfoAction());

    return () => {
      dispatch(resetReferralsQuestsAction());
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReferralsWrapper>
      {isLoading && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
              }}
              spin
            />
          }
        />
      )}

      <div className="referrals">
        <div className="content">
          <div className="item">
            <div className="content-link">
              <p className="item-title">referral link</p>
              <p className="url">{url || "Not available"}</p>
            </div>
            {userInfo?.referral_code && (
              <ButtonImage
                className="btn-copy"
                onClick={handleCopy}
                height={40}
                textTransform="unset"
                fontWeight={300}
                fontSize={14}
                type="action"
              >
                Copy
              </ButtonImage>
            )}
          </div>

          <div className="item">
            <div className="content-info">
              <div>
                <p className="item-title">total referral user</p>
                <p className="value">{total_referral}</p>
              </div>

              <div>
                <p className="item-title">unclaimed</p>
                <p className="value">{referral_unclaimed || 0}</p>
              </div>

              <div>
                <p className="item-title">rewards</p>
                <p className="value">{point_unclaimed || 0} <img width={28} height={28} src={FREE_POINT} alt="free-point" /></p>
              </div>
            </div>
            <ButtonImage
              onClick={() => {
                if (point_unclaimed) {
                  handleClaim();
                }
              }}
              height={40}
              disabled={!point_unclaimed}
              textTransform="unset"
              fontWeight={300}
              fontSize={14}
              type="green"
            >
              Claim
            </ButtonImage>
          </div>
        </div>
      </div>
    </ReferralsWrapper>
  );
};

export default Referrals;
