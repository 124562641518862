import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Input, notification, Spin } from 'antd';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LoadingOutlined } from '@ant-design/icons';
import * as yup from 'yup';

import FormControl from '../../../../components/form-control';
import { useAppDispatch } from '../../../../stores';
import ButtonImage from '../../../../components/button-img';
import { oneDecimalPlace } from '../../../../utils/number';
import { withdrawMTelPointAction } from '../../../../stores/screens/telegram/telegram.action';
import { STATUS_NETWORK, SYSTEM_ERROR } from '../../../../constants';

import { WithdrawModalStyled } from './styled';

const WithdrawModal = ({
  isOpenModal,
  title,
  onCancel,
  setModalSuccess,
  fee_percent,
  mtelBalance,
  getInfo,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const schema = yup.object().shape({
    amountMtel: yup
      .number()
      .typeError('Amount must be a number')
      .required('Amount is required')
      .min(1, 'Amount must be at least 1')
      .max(
        mtelBalance,
        `Amount must not exceed ${oneDecimalPlace(mtelBalance)}`
      )
      .test(
        'is-decimal',
        'Amount must have at most 2 decimal places',
        (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
      ),
  });

  const {
    handleSubmit,
    // getValues,
    setValue,
    trigger,
    control,
    formState: { errors, isValid },
    reset,
  } = useForm({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    trigger();

    if (isValid) {
      setLoading(true);

      const result = await dispatch(
        withdrawMTelPointAction({
          amount: data.amountMtel,
          fee_percent,
        })
      );
      setLoading(false);
      if (result?.meta?.requestStatus === STATUS_NETWORK.FULFILLED) {
        notification.success({
          message: 'MTel Point Withdrawal to Wallet Success',
          duration: 5,
        });
      } else {
        let message = result.payload?.message;
        if (
          result.payload?.error === SYSTEM_ERROR.ERROR_TELEGRAM_USER_NOT_FOUND
        ) {
          message = 'Account not linked to Telegram';
        }
        notification.error({
          message,
          duration: 5,
        });
      }
      reset({});
      onCancel();
      getInfo();
    }
  };

  return (
    <WithdrawModalStyled
      open={isOpenModal}
      title={title}
      closable={false}
      footer={null}
      centered
      {...props}
    >
      {loading && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
              }}
              spin
            />
          }
        />
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-10">
          <FormControl backgroundColor flex>
            <label htmlFor="amountMtel">
              Amount<span className="required">*</span>
            </label>
            <Controller
              name="amountMtel"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  autoComplete="off"
                  id="amountMtel"
                  name="amountMtel"
                  placeholder="Amount"
                  maxLength={30}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const amount = parseFloat(newValue);

                    if (!isNaN(amount) && amount > 0) {
                      try {
                        const feePercentValid =
                          !isNaN(fee_percent) && fee_percent >= 0;
                        const fee = feePercentValid
                          ? parseFloat(
                              ((amount * fee_percent) / 100).toFixed(2)
                            )
                          : 0;

                        const total = parseFloat((amount - fee).toFixed(2));
                        if (!isNaN(fee) && !isNaN(total)) {
                          setValue('fee', oneDecimalPlace(fee));
                          setValue('total', oneDecimalPlace(total));
                        } else {
                          setValue('fee', '');
                          setValue('total', '');
                        }
                      } catch (error) {
                        setValue('fee', '');
                        setValue('total', '');
                      }
                    } else {
                      setValue('fee', '');
                      setValue('total', '');
                    }

                    onChange(newValue);
                  }}
                  value={value || ''}
                ></Input>
              )}
            />
            {errors?.amountMtel && (
              <p className="error">{errors.amountMtel.message}</p>
            )}
          </FormControl>
        </div>
        <div className="mb-10">
          <FormControl backgroundColor flex>
            <label htmlFor="url">{`Transaction fee (${fee_percent}%)`}</label>
            <Controller
              name="fee"
              control={control}
              render={({ field: { value } }) => (
                <Input
                  autoComplete="off"
                  id="fee"
                  name="fee"
                  placeholder="Transaction fee"
                  disabled
                  value={value || ''}
                ></Input>
              )}
            />
          </FormControl>
        </div>
        <div className="mb-10">
          <FormControl backgroundColor flex>
            <label htmlFor="url">Total due</label>
            <Controller
              name="total"
              control={control}
              render={({ field: { value } }) => (
                <Input
                  autoComplete="off"
                  id="total"
                  name="total"
                  placeholder="Total due"
                  disabled
                  value={value || ''}
                ></Input>
              )}
            />
          </FormControl>
        </div>
        <div className="dp-flex btn-group">
          <ButtonImage
            onClick={() => {
              reset({});
              onCancel();
            }}
            type="brown"
            height={50}
            textTransform="unset"
            fontWeight={300}
            fontSize={14}
          >
            Cancel
          </ButtonImage>
          <ButtonImage
            onClick={handleSubmit(onSubmit)}
            type="button"
            height={50}
            textTransform="unset"
            fontWeight={300}
            fontSize={14}
            disabled={!isValid}
          >
            Withdraw
          </ButtonImage>
        </div>
      </Form>
    </WithdrawModalStyled>
  );
};

export default WithdrawModal;
