import { Image, Spin, Tabs } from "antd";
import { useSearchParams } from "react-router-dom";

import { FREE_POINT, TITLE_QUESTS } from "../../theme/images";
import { TAB_QUESTS } from "../../constants";
import TabMCCQuests from "./TabMccQuests";
import TabReferrals from "./Referrals";
import { QuestsStyled } from "./styled";
import { oneDecimalPlace } from "../../utils/number";
import { useAppDispatch, useAppSelector } from "../../stores";
import { useEffect, useState } from "react";
import {
  claimAllQuestsAction,
  getFreePoint,
  getListMCCQuestsAction,
} from "../../stores/screens/quests/quests.action";
import { getUserInfoAction } from "../../stores/screens/auth/auth.action";
import Storage from "../../utils/storage";
import { LoadingOutlined } from "@ant-design/icons";

const Quests = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { freePoint, isLoading } = useAppSelector((state) => state.quests);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
  });

  const items = [
    {
      key: TAB_QUESTS.MCC_QUEST,
      label: "MCC Quests",
    },
    {
      key: TAB_QUESTS.REFERRAL,
      label: "Referral",
    },
  ];

  const tabActive = searchParams.get("tab") || TAB_QUESTS.MCC_QUEST;

  const onChangeTab = (key) => {
    setSearchParams({
      tab: key,
    });
  };

  useEffect(() => {
    dispatch(getFreePoint({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClaimAll = () => {
    dispatch(
      claimAllQuestsAction({
        callback: () => {
          dispatch(getListMCCQuestsAction(filter));
          dispatch(getFreePoint({}));
          const timeoutId = setTimeout(() => {
            dispatch(
              getUserInfoAction({ accessToken: Storage.get("ACCESS_TOKEN") })
            );
          }, 1000);

          return () => clearTimeout(timeoutId);
        },
      })
    );
  };

  return (
    <QuestsStyled>
      {isLoading && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
              }}
              spin
            />
          }
        />
      )}
      <div className="quest-container">
        <div className="title">
          <Image preview={false} src={TITLE_QUESTS} alt="Icon quests" />
        </div>
        <div className="quests-content">
          <div className="header-tabs">
            <Tabs
              items={items}
              activeKey={tabActive}
              onChange={onChangeTab}
              className="quests-tab"
            />
            {tabActive === TAB_QUESTS.MCC_QUEST && (
              <div className="left_group">
                <div
                  onClick={freePoint > 0 ? () => handleClaimAll() : null}
                  className={`button ${freePoint === 0 && "disable"}`}
                >
                  <div className="free-point">
                    {oneDecimalPlace(freePoint)}{" "}
                    <Image
                      preview={false}
                      width={20}
                      height={20}
                      src={FREE_POINT}
                      alt="free-point"
                    />
                  </div>
                  <span style={{ fontSize: "16px" }}>CLAIM ALL</span>
                </div>
              </div>
            )}
          </div>
          {tabActive === TAB_QUESTS.MCC_QUEST && (
            <TabMCCQuests filter={filter} setFilter={setFilter} />
          )}
          {tabActive === TAB_QUESTS.REFERRAL && <TabReferrals />}
        </div>
      </div>
    </QuestsStyled>
  );
};

export default Quests;
