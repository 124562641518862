import { AxiosClient } from "../../../configs/axios/axios";

const apiVersion = process.env.REACT_APP_VERSION;

const RpsTournamentsApi = {
  getMatchResultsRpsTournament: async (params) => {
    const result = await new AxiosClient().get(
      `${apiVersion}/rps-tournament/matches/${params.matchId}/results`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return result;
  },
};

export default RpsTournamentsApi;
