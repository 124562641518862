import styled from "styled-components";
import '../../resources/styles/_fonts.scss'
import { Images } from "../../theme";

export const QuestsStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px;

  .quest-container {
    width: 60%;
    .title {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
    }

    .quests-content {
      background-color: #000000;
      border: 1px solid #5FD87F;
      padding: 10px 20px;  
      border-radius: 10px;

      .header-tabs {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left_group {
          display: flex;
          gap: 10px;

          .free-point {
            color: #42FCA4;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
          }

          .button {
            padding: 6px 20px;
            border: 1px solid #FF934C;
            border-radius: 5px;
            background: linear-gradient(90deg, #FF934C 0%, #FC686F 100%);
            font-weight: 700;
            cursor: pointer;

            &.disable {
              cursor: not-allowed;
              opacity: 0.6;
            }
          }
        }

      }

      .quests-tab {
        display: flex;

        .ant-tabs-nav-wrap {
          border-bottom: 1px solid #5FD87F;
        }

        .ant-tabs-nav-list {
          display: flex;
        }

        .ant-tabs-ink-bar {
          background-color: #5FD87F;
        }

        .ant-tabs-tab {
          .ant-tabs-tab-btn {
            background-image: url(${Images.TAB_UN_ACTIVE_QUESTS});
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 208px;
            height: 54px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color: #a8aeba;
            font-size: 30px;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 24px;
          }
        }

        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            background-image: url(${Images.TAB_ACTIVE_QUESTS});
            color: #fff;
          }
        }
    }
    }
  }
`;
