import { AxiosClient } from "../../../configs/axios/axios";
import { LOCAL_STORAGE_KEY } from "../../../constants";
import Storage from "../../../utils/storage";

const apiVersion = process.env.REACT_APP_VERSION;

const QuestsApi = {
  getListMCCQuests: async (params) => {
    const result = await new AxiosClient().get(`api/quests`, {
      headers: {
        "x-access-token": Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
        "Content-Type": "application/json",
      },
      params,
    });

    return result;
  },

  performQuest: async (id) => {
    const result = await new AxiosClient().post(
      `api/quests/${id}/perform`,
      {},
      {
        headers: {
          "x-access-token": Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          "Content-Type": "application/json",
        },
      }
    );

    return result;
  },

  claimAll: async () => {
    const result = await new AxiosClient().post(
      `api/quests/claim-all`,
      {},
      {
        headers: {
          "x-access-token": Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          "Content-Type": "application/json",
        },
      }
    );

    return result;
  },

  claimQuest: async (id) => {
    const result = await new AxiosClient().post(
      `api/quests/${id}/claim`,
      {},
      {
        headers: {
          "x-access-token": Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          "Content-Type": "application/json",
        },
      }
    );

    return result;
  },

  getReferralQuestInfo: async (params) => {
    const result = await new AxiosClient().get(
      `${apiVersion}/referrals/quest-info`,
      {
        headers: {
          "x-access-token": Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          "Content-Type": "application/json",
        },
        params,
      }
    );

    return result;
  },

  getFreePoint: async (params) => {
    const result = await new AxiosClient().get(
      `api/quests/unclaimed-point`,
      {
        headers: {
          "x-access-token": Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          "Content-Type": "application/json",
        },
        params,
      }
    );

    return result;
  },

  referralClaimQuest: async () => {
    const result = await new AxiosClient().post(
      `${apiVersion}/referrals/quest-claim`,
      {},
      {
        headers: {
          "x-access-token": Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          "Content-Type": "application/json",
        },
      }
    );

    return result;
  },
};

export default QuestsApi;
