import React from 'react';
import { useLocation } from 'react-router';

import {
  CarryOutOutlined,
  LogoutOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';

import useConnectWallet from '../../hooks/useConnectWallet';

import Misc from '../../utils/misc';
import { CHAIN_LIST } from '../../constants/chains';
import ButtonImage from '../../components/button-img';

/** asset */
import { Images } from '../../theme';
import { HeaderStyled } from './styled';

function Header({ accessToken, publicAddress, userInfo }) {
  const { pathname, search } = useLocation();

  const { 
    handleLogout,
    handleSSO, 
    navigate, 
    public_address, 
    auth_token 
  } = useConnectWallet(accessToken);

  const handleLogin = () => {
    search?.includes('source')
      ? navigate(`/login${search}`)
      : navigate('/login');
  };

  return (
    <HeaderStyled data-aos="fade-down">
      <div
        onClick={() => {
          const hasSourceOrReceiveMsg =
            search?.includes('source') || search?.includes('receiveMsg');

          if (!hasSourceOrReceiveMsg) {
            navigate('/');
          }
        }}
        className="logo"
      >
        <img src={Images.LOGO_HEADER} alt="Logo MCC" />
      </div>
      {!pathname.includes('/login') && (
        <div className="actions">
          {!Misc.isMobile && !search?.includes('source') && (
            <>
              {(accessToken || auth_token) && (
                <ButtonImage
                  style={{ height: 60 }}
                  onClick={() => navigate("/quests")}
                >
                  <span style={{ fontSize: 14 }}>Quests</span>
                </ButtonImage>
              )}
              <ButtonImage type="green" style={{ height: 60 }} onClick={handleSSO}>
                <span style={{ fontSize: 14 }}>
                  {!accessToken && !auth_token
                    ? 'Connect to Wallet'
                    : 'My Wallet'}
                </span>
              </ButtonImage>
            </>
          )}

          {!accessToken && !auth_token ? (
            <ButtonImage onClick={handleLogin} type="login" />
          ) : (
            <>
              <div className="address-user">
                {Misc.trimPublicAddress(publicAddress || public_address, 4)}
                <div className="logo-user">
                  {userInfo?.avatar_url ? (
                    <img
                      width={48}
                      height={48}
                      src={userInfo?.avatar_url}
                      alt="logo-user"
                    />
                  ) : (
                    <div className="logo-default">
                      <UserOutlined className="icon-user" />
                    </div>
                  )}

                  <div className="avatar-dropdown">
                    <div className="bg-1">
                      <div className="bg-2">
                        <div className="bg-3">
                          <div className="content-dropdown">
                            <div className="public-address">
                              <a
                                href={`${CHAIN_LIST[1].blockExplorerUrls
                                  }address/${publicAddress || public_address || userInfo?.wallet_address}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {Misc.trimPublicAddress(
                                  publicAddress || public_address || userInfo?.wallet_address,
                                  6
                                )}
                              </a>
                            </div>
                            <div className="divider"></div>
                            <div
                              className="logout"
                              onClick={() => {
                                navigate('/profile');
                              }}
                            >
                              <UserOutlined width={20} height={20} />
                              {userInfo?.user_name.length > 30 ? userInfo.user_name.substring(0, 30) + '...' : (userInfo?.user_name || '...')}
                            </div>
                            {Misc.isMobile && (
                              <div
                                className="logout wallet"
                                onClick={handleSSO}
                              >
                                <WalletOutlined width={20} height={20} />
                                My Wallet
                              </div>
                            )}
                            <div
                              className="logout"
                              onClick={() => {
                                navigate('/management');
                              }}
                            >
                              <CarryOutOutlined width={20} height={20} />
                              Advertise
                            </div>
                            <div className="logout" onClick={handleLogout}>
                              <LogoutOutlined width={20} height={20} />
                              Log Out
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </HeaderStyled>
  );
}

export default Header;
