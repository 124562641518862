import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useAppDispatch, useAppSelector } from "../../stores";
import { Images } from "../../theme";
import { TournamentMatchResultsStyled } from "./styled";
import { getMatchResultsRpsTournamentAction } from "../../stores/screens/rps-tournaments/rps-tournaments.action";

const TournamentMatchResults = () => {
  const { matchId } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { matchResults, isLoading } = useAppSelector(
    (state) => state.rpsTournaments
  );
  const { loser, winner, tournament } = matchResults;

  const start_time = moment(tournament?.start_time).format(
    "HH:mm on MMMM D, YYYY"
  );
  const end_time = moment(tournament?.end_time).format("HH:mm on MMMM D, YYYY");

  useEffect(() => {
    dispatch(getMatchResultsRpsTournamentAction({ matchId: matchId || "" }));
  }, []);

  return (
    <>
      {isLoading ? (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
              }}
              spin
            />
          }
        />
      ) : (
        <TournamentMatchResultsStyled>
          <div onClick={() => navigate("/")} className="logo">
            <img src={Images.LOGO_HEADER} alt="Logo MCC" />
          </div>
          <div className="container">
            <div className="header">
              <img
                src={Images.TOURNAMENT_VICTORY}
                alt="victory icon"
                className="icon-logo"
              />
              <span className="name">{tournament?.name}</span>
            </div>

            <div className="body">
              <div className="item">
                <div className="avatar winner">
                  <img src={winner?.image_url} alt="avatar" />
                </div>
                <span className="name">{winner?.username}</span>
                <span className="score winner">{winner?.point}</span>
              </div>
              <div className="item">
                <div className="avatar">
                  <img src={loser?.image_url} alt="avatar" />
                </div>
                <span className="name">{loser?.username}</span>
                <span className="score">{loser?.point}</span>
              </div>
            </div>

            <div className="footer">
              <b>
                The tournament takes place from &nbsp;
                {start_time} to {end_time}
              </b>
            </div>
          </div>
        </TournamentMatchResultsStyled>
      )}
    </>
  );
};

export default TournamentMatchResults;
