import React from "react";
import { TournamentMatchResultsWrapper } from "./styled";
import { Outlet } from "react-router";
import ScrollToTop from "../../utils/scrollToTop";

const TournamentMatchResultsLayout = () => {
  return (
    <TournamentMatchResultsWrapper>
      <ScrollToTop>
        <Outlet />
      </ScrollToTop>
    </TournamentMatchResultsWrapper>
  );
};

export default TournamentMatchResultsLayout;
