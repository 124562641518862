import React from "react";
import classNames from "classnames";

import { TabsStyled } from "./styled";

const TabsComponent = ({ items, onChangeTab, activeKey, className, ...props }) => {
  const tabItems = items.map((item, index) => ({
    key: String(index),
    label: item.title,
    children: item.content,
  }));

  return (
    <TabsStyled
      {...props}
      defaultActiveKey={tabItems[activeKey]?.key}
      activeKey={tabItems[activeKey]?.key}
      onChange={onChangeTab}
      centered
      className={classNames(className)}
      items={tabItems}
    />
  );
};

export default TabsComponent;
