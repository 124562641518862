import styled from "styled-components";

export const ProfileTabStyled = styled.div`
  width: 100%;

  .btn-wallet {
    position: absolute;
    z-index: 11;
    right: 20px;
    top: 210px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    background: #156df2;

    span {
      font-weight: 600;
    }
  }

  .ant-progress {
    position: relative;

    .ant-progress-inner {
      width: 56px !important;
      height: 56px !important;
      font-size: 16px;
      position: absolute;
      z-index: 11;
      left: 15%;
      top: -10px;

      .ant-progress-text {
        color: #fff;
        font-weight: 700;
        font-size: 14px;
      }
    }

    &.level {
      .ant-progress-inner {
        left: 20%;
      }
    }
  }

  .dp-flex {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .mobile-bg {
    display: block;
    background-image: url(${(props) => props.bgImage});
    height: 230px;
    background-size: cover;
    background-position: center center;
    border-radius: 10px;
    margin: 0 auto;
  }

  .logo-default {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    box-shadow: 0px 0px 8px 2px #2b37bd;
    background: linear-gradient(180deg, #2773da 0%, #68d8ff 100%),
      radial-gradient(63% 63% at 50% 50%, #2773da 0%, rgba(0, 0, 0, 0) 100%);

    .icon-user {
      font-size: 60px;
    }

    position: absolute;
    bottom: -50px;
    left: 8%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }

  .user {
    width: 100%;
    position: relative;
  }
  .avatar {
    width: 156px;
    height: 156px;
    position: absolute;
    bottom: -50px;
    left: 8%;
    transform: translateX(-50%);
    border-radius: 50%;
    box-shadow: 0px 0px 8px 2px #2b37bd;
    border: 4px solid #156df2;
    z-index: 10;
  }

  .groups {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 100px 20px 20px 20px;
    margin-top: -32px;
    z-index: 9;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.09);
    background: linear-gradient(
        180deg,
        rgba(21, 109, 242, 0) 62.5%,
        rgba(21, 109, 242, 0.5) 192.5%
      ),
      linear-gradient(41deg, rgba(21, 109, 242, 0) 72.42%, #156df2 195.43%),
      #0c0d22;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);

    .form-profile {
      .ant-typography {
        color: #fff;
      }

      .ant-form-item {
        color: #fff;
        width: 450px;
      }

      .ant-input-group-wrapper {
        border-radius: 20px;
      }

      .ant-input-affix-wrapper {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      .ant-input-group-addon {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }

      .ant-input {
        color: #fff;
        height: 40px;
      }

      .ant-input-group-wrapper,
      .ant-input-group-addon,
      .ant-input-affix-wrapper {
        background: rgba(255, 255, 255, 0.09);
        border: 1px solid rgba(255, 255, 255, 0.09);
        color: #fff;
      }

      .input-email {
        .ant-input {
          background: rgba(255, 255, 255, 0.09);
          border: 1px solid rgba(255, 255, 255, 0.09);
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          padding: 0 14px;
        }
      }

      .ant-input-suffix {
        cursor: pointer;
        transition: box-shadow 0.3s ease-in-out;
        padding: 0px 4px;

        &:hover {
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
        }
      }

      .input-wallet {
        .ant-input-affix-wrapper {
          padding: 0 14px;
        }
      }

      .input-referral {
        width: 578px;
        margin-bottom: 0;

        .ant-input-affix-wrapper {
          border: 1px solid rgba(255, 255, 255, 0.09);
          background: #0c0d22;
          color: #fff;
          border-radius: 10px;
        }

        .ant-input-suffix {
          cursor: pointer;
          transition: box-shadow 0.3s ease-in-out;
          padding: 2px 40px;
          background: #156df2;
          border-radius: 10px;
        }
      }

      .anticon {
        color: #fff;
        font-size: 20px;
      }
    }

    .telegram {
      width: 435px;

      .groups-wallet {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.09);
        background: linear-gradient(0deg, #2e2f55 0%, #2e2f55 100%),
          rgba(255, 255, 255, 0.09);

        label {
          color: #fff;
          font-weight: 600;
          text-align: left;
        }

        .balance {
          text-align: center;
          margin: 35px 0px;
          color: #fff;
          font-weight: 700;
          font-size: 16px;
        }

        .btn-withdraw {
          width: 65%;
          height: 34px;
          margin: 0 auto;
          border-radius: 10px;
          background: #156df2;

          span {
            font-weight: 600;
          }
        }
      }

      .play-mcc {
        height: 46px;
        width: 100%;
        border-radius: 10px;
        background: #156df2;

        .icon {
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }
      }
    }

    &.not-telegram {
      align-items: end;
    }

    &.have-telegram {
      .telegram {
        margin-top: 24px;
      }
    }
  }

  @media (max-width: 768px) {
    .dp-flex {
      flex-direction: column;
    }
    .title {
      width: 100%;
    }

    .divider {
      width: 100%;
      display: block;
    }
    .user {
      width: 100%;
      position: relative;
    }
    .desktop-bg {
      display: none;
    }
    .mobile-bg {
      display: block;
      background-image: url(${(props) => props.bgImage});
      width: 100%;
      height: 150px;
      background-size: cover;
      background-position: center center;
      border-radius: 10px;
    }

    .avatar {
      width: 150px;
      height: 150px;
      position: absolute;
      bottom: -50px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
      border: 5px solid black;
    }

    .form-profile {
      width: 100%;

      .ant-input {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .dp-flex-btn {
      flex-direction: column;
    }
  }
`;
