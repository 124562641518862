import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";

import QuestsApi from "./quests.api";
import Confirmable from "../../../components/confirmable";

export const getListMCCQuestsAction = createAsyncThunk(
  "GET_LIST_MCC_QUESTS",
  async (params, { _, rejectWithValue }) => {
    try {
      const response = await QuestsApi.getListMCCQuests(params);

      return response;
    } catch (err) {
      notification.error({
        message: err?.message,
        duration: 5,
      });

      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const performQuestAction = createAsyncThunk(
  "PERFORM_QUEST",
  async (payload, { _, rejectWithValue }) => {
    try {
      const { id, callback } = payload;
      const response = await QuestsApi.performQuest(id);

      if (callback) {
        callback();
      }

      return response;
    } catch (err) {
      notification.error({
        message: err?.message,
        duration: 5,
      });

      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const claimQuestAction = createAsyncThunk(
  "CLAIM_QUEST",
  async (payload, { _, rejectWithValue }) => {
    try {
      const { id, callback } = payload;
      const response = await QuestsApi.claimQuest(id);

      if (callback) {
        callback();
      }

      notification.success({
        message: "Claim Quest Success",
        duration: 5,
      });

      return response;
    } catch (err) {
      if (err?.error === "ERR_USER_BLOCKED_BY_WRONG_PASSWORD_TRANSACTION") {
        Confirmable.open({
          content:
            "Your wallet has been locked due to an incorrect transaction password.",
          hideCancelButton: true,
          isShowIconExclamation: true,
          hideIconClose: true,
          acceptButtonText: "Contact Us",
          onOk: () =>
            window.open(
              `${process.env.REACT_APP_SITE_WALLET_URL}/contact-us`,
              "_blank"
            ),
        });
      } else {
        notification.error({
          message: err?.message,
          duration: 5,
        });
      }

      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const getReferralQuestInfoAction = createAsyncThunk(
  "GET_REFERRAL_QUEST_INFO",
  async (params, { _, rejectWithValue }) => {
    try {
      const response = await QuestsApi.getReferralQuestInfo(params);

      return response;
    } catch (err) {
      notification.error({
        message: err?.message,
        duration: 5,
      });

      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const getFreePoint = createAsyncThunk(
  "GET_FREE_POINT",
  async (params, { _, rejectWithValue }) => {
    try {
      const response = await QuestsApi.getFreePoint(params);

      return response;
    } catch (err) {
      notification.error({
        message: err?.message,
        duration: 5,
      });

      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const claimAllQuestsAction = createAsyncThunk(
  "CLAIM_ALL",
  async (payload, { _, rejectWithValue }) => {
    try {
      const { callback } = payload;
      const response = await QuestsApi.claimAll();

      if (callback) {
        callback();
      }

      notification.success({
        message: "Claim all successfully!",
        duration: 5,
      });

      return response;
    } catch (err) {
      if (err?.error === "ERR_USER_BLOCKED_BY_WRONG_PASSWORD_TRANSACTION") {
        Confirmable.open({
          content:
            "Your wallet has been locked due to an incorrect transaction password.",
          hideCancelButton: true,
          isShowIconExclamation: true,
          hideIconClose: true,
          acceptButtonText: "Contact Us",
          onOk: () =>
            window.open(
              `${process.env.REACT_APP_SITE_WALLET_URL}/contact-us`,
              "_blank"
            ),
        });
      } else {
        notification.error({
          message: err?.message,
          duration: 5,
        });
      }

      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const referralClaimQuestAction = createAsyncThunk(
  "REFERRAL_CLAIM_QUEST",
  async (payload, { _, rejectWithValue }) => {
    try {
      const { callback } = payload;
      const response = await QuestsApi.referralClaimQuest();

      if (callback) {
        callback();
      }

      notification.success({
        message: "Claim Referral Success",
        duration: 5,
      });

      return response;
    } catch (err) {
      if (err?.error === "ERR_USER_BLOCKED_BY_WRONG_PASSWORD_TRANSACTION") {
        Confirmable.open({
          content:
            "Your wallet has been locked due to an incorrect transaction password.",
          hideCancelButton: true,
          isShowIconExclamation: true,
          hideIconClose: true,
          acceptButtonText: "Contact Us",
          onOk: () =>
            window.open(
              `${process.env.REACT_APP_SITE_WALLET_URL}/contact-us`,
              "_blank"
            ),
        });
      } else {
        notification.error({
          message: err?.message,
          duration: 5,
        });
      }

      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);
