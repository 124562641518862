import 'antd/dist/antd.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import Providers from './Providers';
import Init from './contexts/init'
import reportWebVitals from './reportWebVitals';
import { store } from './stores/index';
import Storage from './utils/storage';

import Theme from './theme';
import './resources/styles';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <Theme>
      <BrowserRouter>
        <Init>
          <Providers>
            <App />
          </Providers>
        </Init>
      </BrowserRouter>
    </Theme>
    {/* </React.StrictMode> */}
  </Provider>
);

if (window.ethereum) {
  window.ethereum.on('networkChanged', async function (networkId) {
    await Storage.set('chain', Number(networkId));
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
