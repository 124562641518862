import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Web3 from 'web3';
import { notification } from 'antd';
import {
  ADAPTER_EVENTS,
  CHAIN_NAMESPACES,
  WALLET_ADAPTERS,
} from '@web3auth/base';
import { getPublicCompressed } from '@toruslabs/eccrypto';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { MetamaskAdapter } from '@web3auth/metamask-adapter';
import { Web3Auth } from '@web3auth/modal';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment';
import queryString from "query-string";

import Storage from '../utils/storage';
import Request from '../utils/request';
import { useAppDispatch, useAppSelector } from '../stores';
import useSSOLogin from '../hooks/ssoLogin';
import { getNetworkSelected, removeLocalLogout } from '../utils/auth';
import {
  getUserInfoAction,
  refreshTokenLoginAction,
  triggerLoginAction,
} from '../stores/screens/auth/auth.action';

import MaskLoading from '../components/mask-loading';
import useModalInvalidEmail from '../hooks/useModalInvalidEmail';

import Confirmable from '../components/confirmable';

export const Web3AuthContext = createContext({
  web3auth: null,
  web3authNoModal: null,
  provider: null,
  providerMetamask: null,
  isLoading: false,
  currentAccount: null,
  accessToken: null,
  userInfo: null,
  userId: null,
  expiredToken: null,
  refreshToken: null,
  handleConnectWallet: async () => {},
  handleLogout: async () => {},
  handleSocialConnect: async () => {},
  getAccounts: async () => {},
  getUserInfo: async () => {},
  getIdToken: async () => {},
  getAppPubKey: async () => {},
  setCurrentAccount: () => {},
  setAccessToken: () => {},
  setUserInfo: () => {},
  setUserId: () => {},
  setExpiredToken: () => {},
  setRefreshToken: () => {},
});

export function useWeb3Auth() {
  return useContext(Web3AuthContext);
}
const env = process.env.REACT_APP_ENV;
const networkWeb3auth = process.env.REACT_APP_WEB3_AUTH_NETWORKS;
const network = getNetworkSelected(env, Storage.get('chain'));
const currentChainConfig = {
  displayName: network?.displayName,
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: `0x${Number(network?.chainId).toString(16)}`,
  rpcTarget: network?.rpcUrls[0],
  blockExplorer: network?.blockExplorerUrls[0],
  ticker: network?.symbol,
  tickerName: network?.currency,
  decimals: network?.decimals,
};

const clientId = process.env.REACT_APP_CLIENT_ID_WEB3_AUTH;
const sessionTime = process.env.REACT_APP_SESSION_TIME;

export const Web3AuthProvider = ({ children }) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { hookLoginSSO } = useSSOLogin();
  const dispatch = useAppDispatch();
  const { open: openModalInvalidEmail } = useModalInvalidEmail();

  const [web3auth, setWeb3auth] = useState(null);
  const [web3authNoModal, setWeb3authNoModal] = useState(null);
  const [provider, setProvider] = useState(null);
  const [providerMetamask, setProviderMetamask] = useState(null);
  const [userId, setUserId] = useState(Storage.get('USER_ID') || null);
  const [currentAccount, setCurrentAccount] = useState(
    Storage.get('PUBLIC_ADDRESS') || null
  );
  const [accessToken, setAccessToken] = useState(
    Storage.get('ACCESS_TOKEN') || null
  );
  const [expiredToken, setExpiredToken] = useState(
    Storage.get('EXPIRED_TOKEN') || null
  );
  const [refreshToken, setRefreshToken] = useState(
    Storage.get('REFRESH_TOKEN') || null
  );
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { authToken } = useAppSelector((state) => state.auth);

  const handleCrossSiteLogin = async () => {
    if (search?.includes('authToken')) {
      const { payload, accessToken } = await hookLoginSSO();
      const { status, data } = payload;
      if (status) {
        navigate({ search: null });
        setAccessToken(accessToken);
        setUserId(data.user_id);
        setCurrentAccount(data.wallet_address);
      }
    }
  };

  const closeModal = () => {
    window.close();
  }

  const triggerLogin = async (source, gsid) => {
    const { payload } = await dispatch(
      triggerLoginAction({
        source,
        gsid
      })
    );
    if(payload?.status) {
      Confirmable.open({
        content: <div style={{ textAlign: 'start', marginTop: '20px' }}>Login successful. <br/> Please close this window to continue using the application</div>,
        hideCancelButton: true,
        isShowIconSuccess: true,
        acceptButtonText: 'Close',
        width: 515,
        onOk: closeModal,
      });
    }
  }

  useEffect(() => {
    if (search.includes('source')) {
      const source = queryString.parse(search)?.source;
      const gsid = queryString.parse(search)?.gsid;
      if (accessToken) {
        triggerLogin(source, gsid);
      }
    }
  }, [search]);

  const logoutUrl = async () => {
    if(pathname.includes('/logout')){
      await handleLogout();
      navigate('/');
    }
  }

  useEffect(() => {
    const subscribeAuthEvents = (web3authInstance) => {
      web3authInstance.on(ADAPTER_EVENTS.CONNECTED, async (data) => {
        console.log('ADAPTER_EVENTS --------------- CONNECTED');
        localStorage.setItem('Web3Auth-cachedAdapter', data.adapter);
      });

      web3authInstance.on(ADAPTER_EVENTS.CONNECTING, () => {
        console.log('ADAPTER_EVENTS --------------- CONNECTING');
      });

      web3authInstance.on(ADAPTER_EVENTS.DISCONNECTED, async () => {
        console.log('ADAPTER_EVENTS --------------- DISCONNECTED');
      });

      web3authInstance.on(ADAPTER_EVENTS.ERRORED, async (error) => {
        if (error?.code === 4001) return;
        console.log('ADAPTER_EVENTS --------------- ERRORED', error);
        notification.error({
          message: 'Network is not supported for registration',
          duration: 5,
        });
      });
    };

    const init = async () => {
      try {
        const web3auth = new Web3Auth({
          clientId,
          // type uiConfig
          sessionTime: Number(sessionTime),
          chainConfig: currentChainConfig,
          web3AuthNetwork: networkWeb3auth,
          uiConfig: {
            mode: 'light',
          },
        });

        const web3authNoModal = new Web3AuthNoModal({
          clientId,
          chainConfig: currentChainConfig,
          sessionTime: Number(sessionTime),
          enableLogging: true,
          web3AuthNetwork: networkWeb3auth,
        });

        const metamaskAdapter = new MetamaskAdapter({
          sessionTime: Number(sessionTime),
        });

        web3authNoModal.configureAdapter(metamaskAdapter);

        subscribeAuthEvents(web3auth);
        subscribeAuthEvents(web3authNoModal);

        setWeb3auth(web3auth);
        setWeb3authNoModal(web3authNoModal);
        await web3auth.initModal({
          modalConfig: {
            openlogin: {
              label: "openlogin",
              loginMethods: {
                apple: {
                  name: "apple",
                  showOnModal: false,
                },
                google: {
                  name: "google",
                  showOnModal: false,
                },
                facebook: {
                  name: "facebook",
                  showOnModal: false,
                },
                discord: {
                  name: "discord",
                  showOnModal: false,
                },
                twitter: {
                  name: "twitter",
                  showOnModal: false,
                },
                github: {
                  name: "github",
                  showOnModal: false,
                },
                line: {
                  name: "line",
                  showOnModal: false,
                },
                twitch: {
                  name: "twitch",
                  showOnModal: false,
                },
                weibo: {
                  name: "weibo",
                  showOnModal: false,
                },
                linkedin: {
                  name: "linkedin",
                  showOnModal: false,
                },
                wechat: {
                  name: "wechat",
                  showOnModal: false,
                },
                kakao: {
                  name: "kakao",
                  showOnModal: false,
                },
                reddit: {
                  name: "reddit",
                  showOnModal: false,
                },
                email_passwordless: {
                  name: "email_passwordless",
                  showOnModal: true,
                },
                sms_passwordless: {
                  name: "sms_passwordless",
                  showOnModal: false,
                },
              },
            },
          },
        });
        await web3authNoModal.init();
        setProvider(web3auth.provider);
        setProviderMetamask(web3authNoModal.provider);
      } catch (error) {
        console.error(error);
      }
    };

    handleCrossSiteLogin();
    init();
  }, []);

  useEffect(() => {
    getAPIUserInfo(accessToken);
    logoutUrl();
  }, [accessToken]);

  const getAPIUserInfo = async (accessToken) => {
    const isCheck = Storage.get('IS_CHECK');
    if (accessToken || isCheck) {
      const { payload } = await dispatch(
        getUserInfoAction({ accessToken: accessToken || isCheck })
      );
      if (payload?.status) {
        setUserInfo(payload?.data);
        setUserId(payload?.data.user_id);
      } else {
        if (payload?.error === 'ERR_USER_INACTIVE') {
          openModalInvalidEmail();
        }
        if (payload?.httpStatus == 401) {
          await handleLogout();
        }
      }
    }
  };

  const refreshAccessToken = useCallback(async () => {
    const res = await dispatch(
      refreshTokenLoginAction({
        refresh_token: authToken?.refresh_token || refreshToken,
      })
    );

    if (res?.payload?.status) {
      const { data } = res?.payload;
      setAccessToken(data.access_token);
      Storage.set('ACCESS_TOKEN', data.access_token);

      Storage.set('EXPIRED_TOKEN', data.expired_at);
      setExpiredToken(data.expired_at);

      Storage.set('REFRESH_TOKEN', data.refresh_token);
      setRefreshToken(data.refresh_token);

      Request.setAccessToken(data.access_token);
    } else {
      notification.error({
        message: 'Something went wrong',
        duration: 5,
      });
    }
  }, []);

  const isTokenExpiring = () => {
    if (!authToken?.expired_at || !expiredToken) {
      return false;
    }
    const timeDifference = (authToken?.expired_at ? authToken?.expired_at : Number(expiredToken)) - moment.utc().unix() * 1000;
    const oneDayMinutes = 86400000;
    return timeDifference <= oneDayMinutes;
  };

  useEffect(() => {
    if (isTokenExpiring()) {
      refreshAccessToken();
    }
  }, [refreshAccessToken]);

  // Connect social
  const handleSocialConnect = async () => {
    if (!web3auth) {
      console.log('web3auth not initialized yet');
      return;
    }

    let timerId = handleClosePopupSocialLogin();
    let web3authProvider;
    try {
      web3authProvider = await web3auth.connect();
      setProvider(web3authProvider);
    } catch (error) {
      web3auth?.loginModal?.closeModal();
      throw error;
    } finally {
      if (timerId) clearTimeout(timerId);
    }
    return web3authProvider;
  };

  const handleClosePopupSocialLogin = () => {
    const timerId = setTimeout(() => {
      const btnClose =
        window?.document.getElementsByClassName('w3ajs-close-btn');
      btnClose?.[0]?.addEventListener('click', () => {
        MaskLoading.close();
        clearTimeout(timerId);
      });
    }, 100);
    return timerId;
  };

  // Connect metamask
  const handleConnectWallet = async () => {
    if (!web3authNoModal) {
      console.log('web3auth not initialized yet');
      return;
    }
    if (
      !web3authNoModal.connected ||
      localStorage.getItem('Web3Auth-cachedAdapter') !==
        WALLET_ADAPTERS.METAMASK
    ) {
      const web3authProvider = await web3authNoModal.connectTo(
        WALLET_ADAPTERS.METAMASK
      );
      setProviderMetamask(web3authProvider);
    }
  };

  //Logout
  const handleLogout = async () => {
    setAccessToken(null);
    setCurrentAccount(null);
    setUserId(null);
    setUserInfo(null);
    removeLocalLogout();

    try {
      if (web3auth.connected) await web3auth.logout();
    } catch (e) {}
    try {
      if (web3authNoModal.connected) await web3authNoModal.logout();
    } catch (e) {}
  };

  const getIdToken = async () => {
    try {
      const { idToken } = await web3authNoModal.authenticateUser();
      return idToken;
    } catch (error) {
      throw {
        ...error,
        keepLogin: true,
      };
    }
  };

  const getUserInfo = async () => {
    try {
      const userInfo = await web3auth.getUserInfo();
      return userInfo;
    } catch (error) {
      return error?.message;
    }
  };

  const getAccounts = async (initProvider = provider) => {
    try {
      if (web3auth.connected) {
        const web3 = new Web3(initProvider);
        const accounts = await web3.eth.getAccounts();
        return accounts;
      }
    } catch (e) {}
    try {
      if (web3authNoModal.connected) {
        const web3 = new Web3(providerMetamask);
        const accounts = await web3.eth.getAccounts();
        return accounts;
      }
    } catch (e) {}
  };

  const getAppPubKey = async () => {
    try {
      const app_scoped_privkey = await web3auth.provider?.request({
        method: 'eth_private_key', // use "private_key" for other non-evm chains
      });

      const app_pub_key = getPublicCompressed(
        Buffer.from(app_scoped_privkey.padStart(64, '0'), 'hex')
      ).toString('hex');

      return app_pub_key;
    } catch (error) {
      return error;
    }
  };

  const contextProvider = {
    web3auth,
    web3authNoModal,
    provider,
    providerMetamask,
    isLoading,
    currentAccount,
    accessToken,
    userInfo,
    userId,
    expiredToken,
    refreshToken,
    setIsLoading,
    handleLogout,
    handleSocialConnect,
    handleConnectWallet,
    getAccounts,
    getUserInfo,
    getIdToken,
    getAppPubKey,
    setCurrentAccount,
    setAccessToken,
    setUserInfo,
    setUserId,
    setExpiredToken,
    setRefreshToken,
  };
  return (
    <Web3AuthContext.Provider value={contextProvider}>
      {children}
    </Web3AuthContext.Provider>
  );
};
