import { Routes, Route } from 'react-router-dom';

import MainLayout from './layouts/Main';
import NotFound from './layouts/NotFound';
import CountryBlocked from './layouts/CountryBlocked';

import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home';
import EventsList from './pages/EventsList';
import EventsDetail from './pages/EventsDetail';
import FaqsList from './pages/FaqsList';
import ManagementLayout from './layouts/Management';
import ManagementPage from './pages/Management';
import Advertise from './pages/Advertise';
import AdvertiseDetail from './pages/AdvertiseDetail';

import Statistics from './pages/Statistics';
import NewAdvertise from './pages/Advertise/CreateAdvertise';
// import VerifyEmail from './pages/Login/verify-email'
import Groups from './pages/Groups';
import ProfileLayout from './layouts/Profile';
import Quests from './pages/Quests';
import QuestsLayout from './layouts/Quests';
import TournamentMatchResults from './pages/TournamentMatchResults';
import TournamentMatchResultsLayout from './layouts/TournamentMatchResults';

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="" element={<Home />} />
        <Route path="/event-list" element={<EventsList />} />
        <Route path="/event-detail/:id" element={<EventsDetail />} />
        <Route path="/faqs-list" element={<FaqsList />} />
        <Route path="/register" element={<Login />} />
        <Route path="/login" element={<Register />} />
        {/* <Route path="/verify-email" element={<VerifyEmail />} /> */}
        {/* <Route path="/contact-us" element={<Contact />} /> */}

      </Route>
      <Route path="/" element={<ManagementLayout />}>
        <Route path="/management" element={<ManagementPage />} />
        <Route path="/advertise" element={<Advertise />} />
        <Route path="/advertise/:id/transaction/:transactionId" element={<AdvertiseDetail />} />
        <Route path="/advertise/new-advertise" element={<NewAdvertise />} />
        <Route path="/statistics" element={<Statistics />} />
      </Route>
      <Route path="/" element={<ProfileLayout />}>
        <Route path="/profile" element={<Groups />} />
      </Route>
      <Route path="/" element={<QuestsLayout />}>
        <Route path="/quests" element={<Quests />} />
      </Route>
      <Route path="/" element={<TournamentMatchResultsLayout />}>
        <Route path="/rps-tournament/:matchId/match-results" element={<TournamentMatchResults />} />
      </Route>
      <Route path="/country-blocked" element={<CountryBlocked />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
