import React from "react";

import {
  CarryOutOutlined,
  LogoutOutlined,
  MenuOutlined,
  UserOutlined,
  WalletOutlined,
} from "@ant-design/icons";

import { Images } from "../../../theme";
import Misc from "../../../utils/misc";
import { ManagementHeaderStyled } from "./styled";

import { CHAIN_LIST } from "../../../constants/chains";
import { oneDecimalPlace } from "../../../utils/number";
import useConnectWallet from "../../../hooks/useConnectWallet";

const ManagementHeader = ({
  accessToken,
  publicAddress,
  userInfo,
  showDrawer = () => {},
  isShowMenu = false,
  className
}) => {

  const { handleLogout, handleSSO, navigate, public_address } =
    useConnectWallet(accessToken);

  return (
    <ManagementHeaderStyled className={className}>
      {Misc.isMobile && isShowMenu ? (
        <MenuOutlined onClick={showDrawer} style={{ fontSize: 20 }} />
      ) : (
        <div onClick={() => navigate("/")} className="logo">
          <img src={Images.LOGO_HEADER} alt="Logo MCC" />
        </div>
      )}
      <div className="address-user">
        <div>
          <div className="mcc-point">
            <span className="point">{oneDecimalPlace(userInfo?.balance)}</span>{" "}
            <span className="small-text">MCC Point</span>
          </div>
          <div className="free-point">
            <span className="point">{oneDecimalPlace(userInfo?.free_point)}</span>{" "}
            <span className="small-text">Free Point</span>
          </div>
        </div>
        <div className="logo-user">
          {userInfo?.avatar_url ? (
            <img
              width={48}
              height={48}
              src={userInfo?.avatar_url}
              alt="logo-user"
            />
          ) : (
            <div className="logo-default">
              <UserOutlined className="icon-user" />
            </div>
          )}
          <div className="avatar-dropdown">
            <div className="bg-1">
              <div className="bg-2">
                <div className="bg-3">
                  <div className="content-dropdown">
                    <div className="public-address">
                      <a
                        href={`${CHAIN_LIST[1].blockExplorerUrls}address/${
                          publicAddress || public_address
                        }`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {Misc.trimPublicAddress(
                          publicAddress || public_address,
                          6
                        )}
                      </a>
                    </div>
                    <div className="divider"></div>
                    <div
                      className="logout"
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      <UserOutlined width={20} height={20} />
                      {userInfo?.user_name.length > 30
                        ? userInfo.user_name.substring(0, 30) + "..."
                        : userInfo?.user_name || "..."}
                    </div>
                    {Misc.isMobile && (
                      <div className="logout wallet" onClick={handleSSO}>
                        <WalletOutlined width={20} height={20} />
                        My Wallet
                      </div>
                    )}
                    <div
                      className="logout"
                      onClick={() => {
                        navigate("/management");
                      }}
                    >
                      <CarryOutOutlined width={20} height={20} />
                      Advertise
                    </div>
                    <div
                      className="logout"
                      onClick={() => {
                        handleLogout();
                        navigate("/");
                      }}
                    >
                      <LogoutOutlined width={20} height={20} />
                      Log Out
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ManagementHeaderStyled>
  );
};

export default ManagementHeader;
