import styled from 'styled-components';
import { Images } from '../../theme';

export const QuestsWrapper = styled.div`
  background-image: url(${Images.BG_QUESTS});
  width: 100%;
  position: relative;
  min-height: 100%;
  background-size: cover;
  background-position: center center;

  .header-quests {
    border-bottom: initial;
    box-shadow: initial;
  }

  footer {
    background: transparent;
  }

  .gap-20 {
    gap: 20px;
  }
  .align-end {
    align-items: flex-end;
  }
  .justify-start {
    justify-content: flex-start;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-between {
    justify-content: space-between;
  }

  .ant-spin-spinning {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-spin-spinning:before {
    content: '';
    background: #ececec;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.2;
  }

  .anticon {
    color: #fff;
  }
`;
