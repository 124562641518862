import styled from "styled-components";

export const TabMCCQuestsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .quests-table {
    table {
      border-spacing: 0 10px;
    }

    .ant-table-thead > tr > th {
      background: linear-gradient( #5FD87F, #178781);
      color: #000000;
      font-weight: 600;
    }

    .ant-table-tbody > tr > td {
     border: 1px solid #178781;

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-right: none;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: none;
      }

      &:not(:first-child):not(:last-child) {
        border-left: none;
        border-right: none;
      }
    }

    .ant-table-content {
      background: #000000;
    }

    .row-claimed {
      background: linear-gradient(90deg, rgba(95, 216, 127, 0.50) 0%, rgba(23, 135, 129, 0.50) 100%);
      td {
        border: none !important;
      }
    }

    .ant-table-pagination {
      .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next {
        background:rgb(12, 39, 38);
        &.ant-pagination-item-active {
          background: linear-gradient(to right, #5FD87F, #178781);
        }
        button {
          border-color: #5FD87F;
          background: #5FD87F;
          &:disabled {
            background: #178781;
            opacity: 0.5;
          }
        }
      }
    }
  }

  table {
    th,
    td {
      text-align: center;
    }

    .explanation {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
    }

    .user-quests-status {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ellipsis {
      display: flex;
      align-items: center;
      gap: 10px;
  
      p {
        white-space: break-spaces;
      }
    }
  }
`;
