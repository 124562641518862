import React, { useState } from "react";
import { ProfileTabStyled } from "./styled";
import { Button, Form, Input, Progress, Typography } from "antd";
import {
  CopyOutlined,
  MailOutlined,
  UserOutlined,
  WalletOutlined,
} from "@ant-design/icons";

import { useAppDispatch } from "../../../stores";
import Toast from "../../../components/toast";
import { BG_COVER } from "../../../theme/images";
import { getUserInfoAction } from "../../../stores/screens/auth/auth.action";
import { oneDecimalPlace } from "../../../utils/number";
import Misc from "../../../utils/misc";
import Storage from "../../../utils/storage";

import WithdrawModal from "./WithdrawModal";
import WalletIcon from "../../../resources/svgs/WalletIcon";
import GameIcon from "../../../resources/svgs/GameIcon";
import useConnectWallet from "../../../hooks/useConnectWallet";
import TelegramIcon from "../../../resources/svgs/TelegramIcon";

const { Title } = Typography;

const ProfileTab = ({ userInfo, params }) => {
  const { handleSSO } = useConnectWallet(Storage.get("ACCESS_TOKEN"));

  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);

  const getInfo = () => {
    dispatch(getUserInfoAction({ accessToken: Storage.get("ACCESS_TOKEN") }));
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(userInfo?.wallet_address).then(() => {
      Toast.show("Copied to clipboard!");
    });
  };

  return (
    <ProfileTabStyled bgImage={BG_COVER}>
      <div className="dp-flex">
        <div className="user">
          <div className="mobile-bg"></div>
          {userInfo?.avatar_url ? (
            <img
              className="avatar"
              width={48}
              height={48}
              src={userInfo?.avatar_url}
              alt="logo-user"
            />
          ) : (
            <div className="logo-default">
              <UserOutlined className="icon-user" />
            </div>
          )}
        </div>
        <Progress
          className="tier"
          type="circle"
          gapPosition="top"
          trailColor="#222336"
          percent={70}
          format={(percent) => (
            <div>
              {percent}
              <div>Tier</div>
            </div>
          )}
          strokeColor={{
            "0%": "#156DF2",
            "100%": "#9747FF",
          }}
        />
        <Progress
          className="level"
          type="circle"
          gapPosition="top"
          trailColor="#222336"
          percent={35}
          format={(percent) => (
            <div>
              {percent}
              <div>Level</div>
            </div>
          )}
          strokeColor={{
            "0%": "#15DCF2",
            "50%": "#64FF45",
            "100%": "#F3FF4C",
          }}
        />

        <Button className="btn-wallet" type="primary" onClick={handleSSO}>
          My Wallet <WalletIcon />
        </Button>
        <div className={`groups ${userInfo?.telegram_coins_balance === null ? 'not-telegram' : 'have-telegram' }`}>
          <Form className="form-profile">
            <Title level={3}>{userInfo?.user_name}</Title>
            <Form.Item className="input-wallet">
              <Input
                addonBefore={<WalletOutlined width={20} height={20} />}
                value={Misc.trimPublicAddress(
                  userInfo?.wallet_address || "",
                  8
                )}
                suffix={
                  <CopyOutlined width={20} height={20} onClick={handleCopy} />
                }
                disabled
              />
            </Form.Item>
            <Form.Item className="input-email">
              <Input
                addonBefore={<MailOutlined width={20} height={20} />}
                value={userInfo?.email || ""}
                disabled
              />
            </Form.Item>
            <Title level={5}>MY REFERRAL LINK</Title>
            <Form.Item className="input-referral">
              <Input
                value={
                  process.env.REACT_APP_SITE_MCC_URL +
                  "/register?ref=" +
                  userInfo?.referral_code
                }
                suffix={
                  <CopyOutlined width={20} height={20} onClick={handleCopy} />
                }
                disabled
              />
            </Form.Item>
          </Form>
          <div className="telegram">
            {userInfo?.telegram_coins_balance !== null && (
              <div className="groups-wallet">
                {userInfo && userInfo?.telegram_coins_balance !== null && (
                  <>
                    <label>MTel Point</label>
                    <div className="balance">
                      {oneDecimalPlace(userInfo?.telegram_coins_balance || 0)}
                    </div>
                  </>
                )}

                {userInfo && userInfo?.linked && (
                  <Button
                    className="btn-withdraw"
                    type="primary"
                    onClick={() => setShowModal(true)}
                  >
                    Withdraw MTel Point to Wallet
                  </Button>
                )}
              </div>
            )}

            <Button className="play-mcc" type="primary">
              <a
                href={`${process.env.REACT_APP_TELEGRAM_SHORT_LINK}?startapp=${
                  userInfo?.linked ? "" : params
                }`}
                target="_blank"
                rel="noreferrer"
              >
                {userInfo?.linked ? (
                  <div className="icon">
                    <GameIcon /> <span>Play MCC Game</span>
                  </div>
                ) : (
                  <div className="icon">
                    <TelegramIcon /> <span>Connect to Telegram</span>
                  </div>
                )}
              </a>
            </Button>
          </div>
        </div>
      </div>
      {showModal && (
        <WithdrawModal
          isOpenModal={showModal}
          title="Withdraw MTel Point to Wallet"
          onCancel={() => {
            setShowModal(false);
          }}
          fee_percent={userInfo?.fee_percent || 0}
          mtelBalance={userInfo?.telegram_coins_balance || 0}
          getInfo={getInfo}
        />
      )}
    </ProfileTabStyled>
  );
};

export default ProfileTab;
