import { createSlice } from "@reduxjs/toolkit";

import { getMatchResultsRpsTournamentAction } from "./rps-tournaments.action";

const initialState = {
  isLoading: false,
  error: {},
  matchResults: {
    tournament: {
      name: "",
      start_time: "",
      end_time: "",
    },
    loser: {
      username: "",
      point: 0,
      image_url: "",
    },
    winner: {
      username: "",
      point: 0,
      image_url: "",
    },
    status: "",
  },
};

const { actions, reducer } = createSlice({
  name: "rps_tournaments_slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMatchResultsRpsTournamentAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getMatchResultsRpsTournamentAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.matchResults = action.payload.data;
        }
      )
      .addCase(getMatchResultsRpsTournamentAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      });
  },
});

export { reducer };
export default actions;
