import { createSlice } from "@reduxjs/toolkit";

import {
  claimAllQuestsAction,
  claimQuestAction,
  getFreePoint,
  getListMCCQuestsAction,
  getReferralQuestInfoAction,
  performQuestAction,
  referralClaimQuestAction,
} from "./quests.action";

const initialState = {
  isLoading: false,
  error: {},
  listQuests: {
    items: [],
    limit: 10,
    page: 1,
    totalCount: 0,
    totalPage: 0,
  },
  freePoint: 0,
  referralQuest: {
    isLoading: false,
    info: {
      point_unclaimed: 0,
      total_referral: 0,
    },
    error: {},
  },
};

const { actions, reducer } = createSlice({
  name: "quests_slice",
  initialState,
  reducers: {
    resetQuestsAction: (state) => {
      state.listQuests = initialState.listQuests;
      state.error = initialState.error;
      state.isLoading = initialState.isLoading;
    },

    resetReferralsQuestsAction: (state) => {
      state.referralQuest = initialState.referralQuest;
      state.error = initialState.error;
      state.isLoading = initialState.isLoading;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListMCCQuestsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getListMCCQuestsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listQuests = {
          items: action.payload.data,
          limit: action.payload.limit,
          page: action.payload.page,
          totalCount: action.payload.totalCount,
          totalPage: action.payload.totalPage,
        };
      })
      .addCase(getListMCCQuestsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      })

      .addCase(performQuestAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(performQuestAction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(performQuestAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      })

      .addCase(claimQuestAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(claimQuestAction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(claimQuestAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      })

      .addCase(getReferralQuestInfoAction.pending, (state) => {
        state.referralQuest.isLoading = true;
      })
      .addCase(getReferralQuestInfoAction.fulfilled, (state, action) => {
        state.referralQuest.isLoading = false;
        state.referralQuest.info = action.payload.data;
      })
      .addCase(getReferralQuestInfoAction.rejected, (state, action) => {
        state.referralQuest.isLoading = false;
        state.referralQuest.error = action.payload;
      })

      .addCase(getFreePoint.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFreePoint.fulfilled, (state, action) => {
        state.isLoading = false;
        state.freePoint = action.payload.data?.reward;
      })
      .addCase(getFreePoint.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(claimAllQuestsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(claimAllQuestsAction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(claimAllQuestsAction.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(referralClaimQuestAction.pending, (state) => {
        state.referralQuest.isLoading = true;
      })
      .addCase(referralClaimQuestAction.fulfilled, (state) => {
        state.referralQuest.isLoading = false;
      })
      .addCase(referralClaimQuestAction.rejected, (state) => {
        state.referralQuest.isLoading = false;
      })
  },
});

export { reducer };
export default actions;
