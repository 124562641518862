import { combineReducers } from '@reduxjs/toolkit';

// import { appReducer } from 'src/stores/app';
import { reducer as accountsReducer } from './screens/accounts/accounts.reducer';
import { reducer as authReducer } from './screens/auth/auth.reducer';
import { reducer as eventsReducer } from './screens/events/events.reducer';
import { reducer as pointsReducer } from './screens/points/points.reducer';
import { reducer as generateReducer } from './screens/generate-vr/generate.reducer';
import { reducer as requestManagementReducer } from './screens/requestManagement/management.reducer';
import { reducer as advertiseReducer } from './screens/advertise/advertise.reducer';
import { reducer as statisticsReducer } from './screens/statistics/statistical.reducer';
import { reducer as telegramReducer } from './screens/telegram/telegram.reducer';
import { reducer as questsReducer } from './screens/quests/quests.reducer';
import { reducer as rpsTournamentsReducer } from './screens/rps-tournaments/rps-tournaments.reducer';

export const reducer = combineReducers({
  // app: appReducer,
  accounts: accountsReducer,
  auth: authReducer,
  events: eventsReducer,
  points: pointsReducer,
  vrCode: generateReducer,
  requestManagement: requestManagementReducer,
  advertise: advertiseReducer,
  statistics: statisticsReducer,
  telegram: telegramReducer,
  quests: questsReducer,
  rpsTournaments: rpsTournamentsReducer,
});
