import styled from "styled-components";
import "../../resources/styles/_fonts.scss";

export const TournamentMatchResultsStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 0 12px 0;

  @media (max-width: 768px) {
    padding: 48px 0 86px 0;
  }

  .logo {
    position: absolute;
    top: 32px;
    left: 92px;

    @media (max-width: 768px) {
      top: 12px;
      left: 0;
    }

    img {
      width: 350px;

      @media (max-width: 768px) {
        width: 250px;
      }
    }
  }

  .container {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      width: 80%;
      margin-top: 48px;
    }

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 100px;

      @media (max-width: 768px) {
        margin-bottom: 70px;
      }

      .icon-logo {
        height: 156px;

        @media (max-width: 768px) {
          width: 100%;
          height: auto;
        }
      }

      .name {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 48px;
        line-height: 100%;
        font-style: italic;
        font-weight: 900;
        letter-spacing: 0;
        margin-top: -20px;
        text-align: center;

        @media (max-width: 768px) {
          font-size: 28px;
          margin-top: 0;
        }

        &::after,
        &::before {
          content: "";
          display: block;
          width: 200px;
          height: 2px;
          background-color: white;
          border-radius: 50%;
          opacity: 0.8;

          @media (max-width: 768px) {
            display: none;
          }
        }

        &::before {
          margin-right: 5px;
        }

        &::after {
          margin-left: 20px;
        }
      }
    }

    .body {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;

        .avatar {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          border: 4px solid #fff;
          margin-bottom: 10px;

          @media (max-width: 768px) {
            width: 100px;
            height: 100px;
          }

          &.winner {
            border: 4px solid #ffcc00;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .name {
          font-size: 24px;
          font-weight: 700;
          color: #fff;

          @media (max-width: 768px) {
            font-size: 20px;
          }
        }

        .score {
          font-size: 225px;
          font-weight: 700;
          line-height: 100%;
          color: #fff;
          margin-top: -15px;
          text-shadow: 0px 4px 4px #00000066;

          @media (max-width: 768px) {
            font-size: 170px;
          }

          &.winner {
            color: #ffcc00;
            text-shadow: 0px 0px 10px #ff8000;
          }
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex: 1;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      font-style: italic;
      font-family: "Nobel Uno";

      @media (max-width: 768px) {
        font-size: 14px;
        align-items: center;
      }
    }
  }
`;
